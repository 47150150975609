'use strict';

(function() {
  function RepairResource($resource) {
    return $resource('api/v1/repairs/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      updateOnDragDrop: {
        method: 'PUT',
        params: {
          controller: 'drag-drop-images'
        }
      },
      linkFinding: {
        method: 'PATCH',
        params: {
          controller: 'link-finding'
        }
      },
      moveRepair: {
        method: 'PUT',
        params: {
          id: '@_id',
          controller: 'move'
        }
      },
      /* getRepairsForPrint: {
        method: 'GET',
        params: {
          id: 'print'
        }
      },
      deleteImage: {
        method: 'DELETE',
        params: {
          controller: 'image'
        }
      },
      exportRepairsForTurbine: {
        method: 'GET',
        responseType: "arraybuffer",
        params: {
          id: 'export'
        }
      }, */
      addImage: {
        method: 'PATCH',
        params: {
          controller: 'image'
        }
      },
      importRepairImage: {
        method: 'POST',
        params: {
          id: 'import',
          controller: 'repair-image'
        }
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Repair', RepairResource);

})();
